import {
  Links,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  isRouteErrorResponse,
  useRouteError,
} from '@remix-run/react'
import {
  ExternalScripts,
  type ExternalScriptsHandle,
} from 'remix-utils/external-scripts'
import { ServerOnly } from 'remix-utils/server-only'

import type { LinksFunction } from '@remix-run/node'
import './tailwind.css'
import { trackingId } from './utils/gtag'

export const links: LinksFunction = () => {
  return [
    {
      rel: 'preconnect',
      href: 'https://fonts.gstatic.com',
      crossOrigin: 'anonymous',
    },
    {
      rel: 'preconnect',
      href: 'https://cdn.fontshare.com',
      crossOrigin: 'anonymous',
    },
  ]
}

export const handle: ExternalScriptsHandle = {
  scripts: [
    {
      src: 'https://www.googletagmanager.com/gtm.js?id=GTM-TMHR4TG4',
      async: true,
    },
    {
      src: `https://www.googletagmanager.com/gtag/js?id=${trackingId}`,
      async: true,
    },
  ],
}

interface DocumentProps {
  children: React.ReactNode
  title?: string
}

export function Layout({ children, title }: DocumentProps) {
  return (
    <html lang="en" className="h-full antialiased bg-white scroll-smooth">
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link
          rel="stylesheet"
          href="https://api.fontshare.com/v2/css?f[]=cabinet-grotesk@800,500,700&display=swap"
        />
        <link
          rel="stylesheet"
          href="https://fonts.googleapis.com/css2?family=Inter:wght@100..900&family=Poppins:ital,wght@0,300;0,400;0,700;1,300;1,400&display=swap"
        />
        {title ? <title>{title}</title> : null}
        <Meta />
        <Links />
      </head>
      <body className="flex flex-col min-h-full">
        <ServerOnly>
          {() => (
            <script
              async
              id="gtag-init"
              dangerouslySetInnerHTML={{
                __html: `
                  window.dataLayer = window.dataLayer || [];
                  window.dataLayer.push({ "gtm.start": new Date().getTime(), "event": "gtm.js" });
                  function gtag(){dataLayer.push(arguments);}
                  gtag('js', new Date());
                `,
              }}
            />
          )}
        </ServerOnly>
        {children}
        <ScrollRestoration />
        <Scripts />
        <ExternalScripts />
      </body>
    </html>
  )
}

export default function App() {
  return <Outlet />
}

export function ErrorBoundary() {
  const error = useRouteError()

  if (isRouteErrorResponse(error)) {
    return (
      <div className="w-full mx-auto prose prose-2xl home prose-stone prose-h1:font-bold">
        <main className="grid min-h-full px-6 py-24 bg-white place-items-center sm:py-32 lg:px-8">
          <div className="text-center">
            <p className="text-base font-semibold text-blue-600">
              {error.status}
            </p>
            <h1 className="mt-4 text-3xl font-bold tracking-tight text-stone-900 sm:text-5xl">
              {error.status === 404
                ? 'Page not found'
                : 'Oh no! We have a problem'}
            </h1>
            {error.status === 404 ? (
              <p className="mt-6 text-xl leading-7 text-stone-600">
                Sorry, we couldn’t find the page you’re looking for.
              </p>
            ) : error.statusText ? (
              <div className="mt-6 text-xl leading-relaxed text-stone-600">
                <p>{error.statusText}</p>
              </div>
            ) : (
              <div className="mt-6 text-xl leading-relaxed text-stone-600">
                <p>
                  We're really sorry about this. It looks like something has
                  broken.
                </p>
                <p>
                  Don't worry, our team has been notified and we'll be working
                  on a fix in no time. Reach out to customer support if you
                  would like us to work with you directly.
                </p>
              </div>
            )}

            {/* <div className="flex items-center justify-center mt-10 gap-x-6">
              <a
                href=""
                className="text-sm font-semibold no-underline text-stone-900"
              >
                Contact support <span aria-hidden="true">&rarr;</span>
              </a>
            </div> */}
          </div>
        </main>
      </div>
    )
  }
  if (error instanceof Error) {
    return (
      <div>
        <h1>Error</h1>
        <p>{error.message}</p>
        <p>The stack trace is:</p>
        <pre>{error.stack}</pre>
      </div>
    )
  }
  return <h1>Unknown Error</h1>
}
